<template lang="pug">
.container(ref="container")
</template>

<script>
import {
  Scene,
  OrthographicCamera,
  WebGLRenderer,
  SphereGeometry,
  Mesh,
  MeshStandardMaterial,
  HemisphereLight,
  DirectionalLight,
  VideoTexture,
  LinearFilter,
  UnsignedByteType,
  PMREMGenerator,
  TextureLoader,
} from 'three';

import GLTFLoader from 'three-gltf-loader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import Stats from 'three/examples/jsm/libs/stats.module.js';

import { onMounted, ref } from 'vue';

window.__DEBUG__ = false;

function createBubble(options) {
  const { size, position, rotation, color, heightMap, alphaMap, envMap } = options;
  const geometry = new SphereGeometry(size, 64, 64);
  const material = new MeshStandardMaterial({
    color: color,
    envMap: envMap,
    // map: heightMap,
    alphaMap: alphaMap,
    transparent: true,
    metalness: 0.2,
    roughness: 0.1,
    bumpMap: heightMap,
    bumpScale: 2.0,
    displacementMap: heightMap,
    displacementScale: 0.5,
  });

  const sphere = new Mesh(geometry, [material]);

  sphere.position.x = position.x;
  sphere.position.y = position.y;
  sphere.position.z = position.z;

  sphere.rotation.x = rotation.x;
  sphere.rotation.y = rotation.y;
  sphere.rotation.z = rotation.z;

  sphere.castShadow = true;
  sphere.receiveShadow = true;

  return sphere;
}

function loadVideoTexture(src) {
  const video = document.createElement('video');
  return new Promise((resolve, reject) => {
    video.src = src;
    video.load();

    // workaround for wechat
    video.setAttribute('webkit-playsinline', 'webkit-playsinline');
    video.setAttribute('x-webkit-airplay', 'true');
    video.setAttribute('playsinline', 'true');
    video.setAttribute('x5-video-player-type', 'h5');
    video.setAttribute('x5-video-player-fullscreen', 'false');
    video.preload = 'auto';

    video.muted = true;
    video.loop = true;
    const videoLoadedListener = (e) => {
      if (e.type == 'canplay') {
        video.play();
        const videoTexture = new VideoTexture(video);
        videoTexture.minFilter = LinearFilter;
        videoTexture.magFilter = LinearFilter;
        resolve(videoTexture);
      } else {
        reject(e);
      }
      video.removeEventListener('canplay', videoLoadedListener);
      video.removeEventListener('abort', videoLoadedListener);
      video.removeEventListener('error', videoLoadedListener);
    };
    video.addEventListener('canplay', videoLoadedListener);
    video.addEventListener('abort', videoLoadedListener);
    video.addEventListener('error', videoLoadedListener);
  });
}

function loadHdrTexture(url, pmremGenerator) {
  return new Promise((resolve, reject) => {
    new RGBELoader().setDataType(UnsignedByteType).load(
      url,
      (texture) => {
        return resolve(pmremGenerator.fromEquirectangular(texture).texture);
      },
      undefined,
      (err) => {
        return reject(err);
      },
    );
  });
}

function loadGltf(url) {
  const loader = new GLTFLoader();
  return new Promise((resolve, reject) => {
    loader.load(
      url,
      (gltf) => {
        resolve(gltf);
      },
      undefined,
      (err) => {
        reject(err);
      },
    );
  });
}

function convertLightCastingShadow(light) {
  light.castShadow = true;
  light.shadow.radius = 8; // Make the light soft
  light.shadow.mapSize.width = 256;
  light.shadow.mapSize.height = 256;
}

export default {
  name: 'Bubble',
  setup() {
    const container = ref(null);
    onMounted(async () => {
      const stats = new Stats();
      container.value.appendChild(stats.dom);

      const scene = new Scene();
      const aspect = window.innerWidth / window.innerHeight;
      const camera = new OrthographicCamera(-60 * aspect, 60 * aspect, 60, -60, 0.01, 100);

      const renderer = new WebGLRenderer({ antialias: true, powerPreference: 'high-performance' });
      renderer.shadowMap.enabled = true;
      renderer.setSize(window.innerWidth, window.innerHeight);
      container.value.appendChild(renderer.domElement);

      // Camera Initializing
      camera.position.z = 60;
      camera.zoom = aspect > 1.0 ? 2.0 : 0.8;
      camera.updateProjectionMatrix();

      // Lighting
      const hemisphereLight = new HemisphereLight(0xeeeeee, 0xffffff, 0.4);
      scene.add(hemisphereLight);
      const directionalLight = new DirectionalLight(0x333333);
      directionalLight.position.set(0, 0, 100);
      convertLightCastingShadow(directionalLight);
      scene.add(directionalLight);
      const directionalLight2 = new DirectionalLight(0x333333);
      convertLightCastingShadow(directionalLight2);
      scene.add(directionalLight2);

      // Load Models
      const textModel = (await loadGltf(require('@/assets/bubble/models/pickyourcolor.gltf')))
        .scene;
      const textMaterial = new MeshStandardMaterial({
        color: 0xffffff,
        metalness: 0.0,
        roughness: 1.0,
      });
      textModel.traverse((o) => {
        if (o.isMesh) {
          o.material = textMaterial;
          o.receiveShadow = true;
        }
      });
      textModel.scale.set(0.05, 0.05, 0.05);
      textModel.position.set(5.0, -2.0, -15.0);
      // textModel.castShadow = true;
      textModel.receiveShadow = true;
      scene.add(textModel);

      // Load Textures
      const pmremGenerator = new PMREMGenerator(renderer);
      const bubbleHeightMap = await loadVideoTexture(require('@/assets/bubble/maps/heightMap.mp4'));
      const envMap = await loadHdrTexture(
        require('@/assets/bubble/env/back_color.hdr'),
        pmremGenerator,
      );
      scene.environment = envMap;
      // scene.background = envMap;
      const alphaMap = new TextureLoader().load(require('@/assets/bubble/maps/alpha.png'));

      // Create Bubbles
      const bubbles = [
        {
          //1-黄色
          size: 10,
          position: { x: -40, y: 0, z: 0 },
          rotation: { x: 0, y: 0, z: 0 },
          color: 0xffff33,
          envMap: envMap,
          alphaMap: alphaMap,
          heightMap: bubbleHeightMap,
        },
        {
          //2-橙色
          size: 4,
          position: { x: -30, y: 18, z: 0 },
          rotation: { x: 0, y: 0.1, z: 0 },
          color: 0xff4829,
          envMap: envMap,
          alphaMap: alphaMap,
          heightMap: bubbleHeightMap,
        },
        {
          //3-红色
          size: 8,
          position: { x: -16, y: 12, z: 0 },
          rotation: { x: 0, y: 0, z: 0 },
          color: 0xe5192d,
          envMap: envMap,
          alphaMap: alphaMap,
          heightMap: bubbleHeightMap,
        },
        {
          //4-紫红色
          size: 5.5,
          position: { x: 0, y: 22, z: 0 },
          rotation: { x: 0, y: 0, z: 0 },
          color: 0xf92a86,
          envMap: envMap,
          alphaMap: alphaMap,
          heightMap: bubbleHeightMap,
        },
        {
          //5-
          size: 2,
          position: { x: 5, y: 10, z: 0 },
          rotation: { x: 0, y: 0, z: 0 },
          color: 0xffff77,
          envMap: envMap,
          alphaMap: alphaMap,
          heightMap: bubbleHeightMap,
        },
        {
          //6-蓝色
          size: 4,
          position: { x: 14, y: 14, z: 0 },
          rotation: { x: 0, y: 0, z: 0 },
          color: 0x0a88ff,
          envMap: envMap,
          alphaMap: alphaMap,
          heightMap: bubbleHeightMap,
        },
        {
          //7-
          size: 6,
          position: { x: 30, y: 9, z: 0 },
          rotation: { x: 0, y: 0, z: 0 },
          color: 0x7777ff,
          envMap: envMap,
          alphaMap: alphaMap,
          heightMap: bubbleHeightMap,
        },
        {
          //8-
          size: 5,
          position: { x: -26, y: -20, z: 0 },
          rotation: { x: 0, y: 0, z: 0 },
          color: 0xff7777,
          envMap: envMap,
          alphaMap: alphaMap,
          heightMap: bubbleHeightMap,
        },
        {
          //9-
          size: 5,
          position: { x: -10, y: -10, z: 0 },
          rotation: { x: 0, y: 0, z: 0 },
          color: 0x77ff77,
          envMap: envMap,
          alphaMap: alphaMap,
          heightMap: bubbleHeightMap,
        },
        {
          //10-
          size: 3,
          position: { x: -3, y: -16, z: 0 },
          rotation: { x: 0, y: 0, z: 0 },
          color: 0x00ff00,
          envMap: envMap,
          alphaMap: alphaMap,
          heightMap: bubbleHeightMap,
        },
        {
          //11-
          size: 8,
          position: { x: 10, y: -13, z: 0 },
          rotation: { x: 0, y: 0, z: 0 },
          color: 0x7777ff,
          envMap: envMap,
          alphaMap: alphaMap,
          heightMap: bubbleHeightMap,
        },
        {
          //12-右下角大绿球
          size: 20,
          position: { x: 50, y: -20, z: 0 },
          rotation: { x: 0, y: 0, z: 0 },
          color: 0x77ff77,
          envMap: envMap,
          alphaMap: alphaMap,
          heightMap: bubbleHeightMap,
        },
      ].map((options) => createBubble(options));
      bubbles.forEach((bubble) => scene.add(bubble));

      const controls = new OrbitControls(camera, renderer.domElement);
      const bubbleSpeeds = Array.apply(null, Array(bubbles.length)).map(
        () => Math.random() * 0.02 - 0.01,
      );
      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        bubbles.forEach((bubble, i) => {
          bubble.rotation.z += bubbleSpeeds[i];
        });
        renderer.render(scene, camera);
        stats.update();
      };

      animate();
    });

    return { container };
  },
};
</script>

<style lang="stylus" scoped>
.container
  position absolute
  margin 0
  top 0
  left 0
</style>
